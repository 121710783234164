class Appeal {
  constructor () {
    this.id = null
    this.full_name = ''
    this.email = ''
    this.passport_id = null
    this.requestAttachment = []
    this.responseAttachment = []
    this.status = null
    this.appeal_date = null
    this.created_at = null
    this.updated_at = null
  }
}

export default Appeal
