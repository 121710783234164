class Organization {
  constructor () {
    this.id = null
    this.name = ''
    this.address = ''
    this.bank = ''
    this.inn = ''
    this.kpp = ''
    this.ogrn = ''
    this.region = ''
    this.city = ''
    this.role = []
    this.checking_account = ''
    this.correspondent_account = ''
    this.bik = null
    this.note = null
  }
}

export default Organization
