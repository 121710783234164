import baseObjectAttributes from '@/components/structures/constructor/baseObjectAttributes'
import _ from 'lodash'

class EavEntity {
  constructor (params) {
    this.id = null
    this.entityName = 'Новый объект'
    this.icon = null
    this.style = {}
    this.baseAttributes = baseObjectAttributes
    this.eavAttributes = []
    _.merge(this, params)
  }
}

export default EavEntity
