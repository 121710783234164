class PassportObject {
  constructor (data) {
    this.type = 'Feature'
    this.properties = {
      id: null,
      element_number: null,
      passport_id: data.passportId,
      eav_entity_id: data.entityId,
      objectImages: [],
      objectFiles: []
    }
    if (data.attributes) {
      if (Array.isArray(data.attributes)) {
        data.attributes.forEach(attribute => {
          this.properties[attribute.name] = null
        })
      } else {
        this.properties = { ...this.properties, ...data.attributes }
      }
    }
    this.geometry = null
  }
}

export default PassportObject
