import Organization from './organization'

class Subscription {
  constructor (data) {
    this.id = null
    this.organization = data ? data.organization : new Organization()
    this.from_date = null
    this.to_date = null
    this.price = ''
    this.access_type = 1
    this.subscriptionPassports = []
  }
}

export default Subscription
