class RequestFellingTicket {
  constructor () {
    this.id = null
    this.passport_id = null
    this.declarant_full_name = ''
    this.declarant_address = ''
    this.phone = null
    this.object_address = ''
    this.cause = ''
    this.application = ['']
    this.declaration_date = null
    this.created_at = null
    this.updated_at = null
    this.fellingTicketId = null
    this.fellingTicket = null
    this.file = null
    this.nasajdeniya = []
    this.passport = null
  }
}

export default RequestFellingTicket
