class User {
  constructor (data) {
    this.username = ''
    this.email = ''
    this.role = []
    this.phone = ''
    this.position = ''
    this.organization = data?.organization ? data?.organization : null
  }
}

export default User
