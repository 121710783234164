class FellingTicket {
  constructor () {
    this.id = null
    this.passport_id = null
    this.declarant_full_name = ''
    this.declarant_address = ''
    this.felling_ticket_id = ''
    this.object_address = ''
    this.date_of_issue = null
    this.action_type = ''
    this.payment = ''
    this.price = null
    this.payment = ''
    this.survey_act_id = ''
    this.trees_to_cut = null
    this.trees_to_trim = null
    this.trees_to_destroy = null
    this.executer_full_name = ''
    this.executive_full_name = ''
    this.request_felling_ticket_id = null
    this.requestFellingTicket = null
    this.created_at = null
    this.updated_at = null
    this.passport = null
    this.file = null
    this.nasajdeniya = []
  }
}

FellingTicket.prototype.fillFromRequestFellingTicket = function () {
  if (this.requestFellingTicket) {
    this.passport_id = this.requestFellingTicket.passport_id
    this.declarant_full_name = this.requestFellingTicket.declarant_full_name
    this.declarant_address = this.requestFellingTicket.declarant_address
    this.object_address = this.requestFellingTicket.object_address
    this.request_felling_ticket_id = this.requestFellingTicket.id
    this.nasajdeniya = this.requestFellingTicket.nasajdeniya
  }
}

export default FellingTicket
