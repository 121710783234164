import EavEntity from '@/components/structures/constructor/eavEntity'
import Organization from '@/components/structures/organization'
import Passport from '@/components/structures/passport'
import Subscription from '@/components/structures/subscription'
import User from '@/components/structures/user'
import PassportObject from '@/components/structures/passportObject'
import Appeal from '@/components/structures/appeal'
import RequestFellingTicket from '@/components/structures/requestFellingTicket'
import FellingTicket from '@/components/structures/fellingTicket'

class StructureFactory {
  constructor (name, data) {
    switch (name) {
      case 'constructor':
        return new EavEntity()
      case 'organization':
        return new Organization()
      case 'passport' :
        return new Passport()
      case 'subscription' :
        return new Subscription(data)
      case 'user' :
        return new User(data)
      case 'object' :
        return new PassportObject(data)
      case 'appeal' :
        return new Appeal()
      case 'request-felling-ticket' :
        return new RequestFellingTicket()
      case 'felling-ticket' :
        return new FellingTicket()
    }
  }
}

export default StructureFactory
