import formatters from '@/components/mixins/formatters'
import StructureFactory from '@/components/structures/structureFactory'
import validationErrors from '@/components/mixins/validationErrors'
import loading from '@/components/mixins/loading'
// todo: объединить с map/detail
export default {
  mixins: [formatters, validationErrors, loading],
  props: {
    propsId: Number
  },
  data () {
    return {
      detail: null,
      params: {}
    }
  },
  computed: {
    updatedTime () {
      return this.detail?.properties?.updated_at ? this.timestampToDate(this.detail?.properties?.updated_at) : '—'
    },
    modelIdName () {
      return 'id'
    },
    modelData () {
      return null
    },
    localDetail () {
      return this.detail
    }
  },
  provide () {
    return {
      $v: this.$v
    }
  },
  methods: {
    processResponse (response) {
      this.detail = response
    },
    async validate () {
      this.isLoading.data = true
      this.$v.$touch()
      await this.checkPending()
      let result = true
      if (this.$v.$anyError) {
        await this.$store.dispatch('systemMessages/error', 'Не удалось сохранить данные. Проверьте правильность заполнения полей')
        result = false
      }
      this.isLoading.data = false
      return result
    },
    async handleSave () {
      if (await this.validate()) {
        await this.save()
      }
      return true
    },
    async handleConfirm () {
      if (await this.validate()) {
        //todo: поправить
        /*this.localDetail.properties.is_verified = true
        await this.save()*/
      }
      return true
    },
    async save () {
      if (!this.isLoading.data) {
        this.isLoading.data = true
        let response = await this.$store.dispatch('server/save', {
          url: this.controllerName + '/detail',
          data: { data: this.detail }
        })
        if (response) {
          this.processResponse(response)
        }

        this.isLoading.data = false
        if (response && this.$route.params[this.modelIdName] === 'create') {
          let params = {}
          params[this.modelIdName] = this.localDetail.id
          await this.$router.replace({ name: this.$route.name, params })
        }
      }
      return true
    },
    handleClose () {
      this.$router.back()
    },
    async handleDelete (hardId) {
      const data = hardId || hardId === 0 ? { hard: hardId } : null
      if (!this.isLoading.data) {
        if (this.$route.params[this.modelIdName] !== 'create') {
          this.isLoading.data = true
          await this.$store.dispatch('server/delete', {
            url: this.controllerName + '/detail/' + this.$route.params[this.modelIdName],
            data
          })
          this.isLoading.data = false
        }
        this.handleClose()
      }
      return true
    },
    async handleRestore () {
      this.$set(this.detail.properties, 'state', 1)
      await this.$store.dispatch('systemMessages/info', 'После восстановления объект нужно сохранить', { root: true })
    },
    async fillData () {
      if (!this.loading) {
        if (this.$route.params[this.modelIdName] === 'create') {
          this.$set(this, 'detail', new StructureFactory(this.controllerName, this.modelData))
        } else if (this.propsId) {
          this.isLoading.data = true
          let response = await this.$store.dispatch('server/get', {
            url: this.controllerName + '/detail/' + this.propsId
          })
          this.processResponse(response)
          this.isLoading.data = false
        } else {
          this.isLoading.data = true
          let response = await this.$store.dispatch('server/get', {
            url: this.controllerName + '/detail/' + this.$route.params[this.modelIdName],
            params: this.params
          })
          this.processResponse(response)
          this.isLoading.data = false
          if (Number(this.$route.params[this.modelIdName]) !== this.localDetail?.id) {
            let params = {}
            params[this.modelIdName] = this.localDetail?.id
            await this.$router.replace({ name: this.$route.name, params })
          }
        }
      }
      return true
    }
  },
  async mounted () {
    return this.fillData()
  }
}
