export default [
  {
    'name': 'id',
    'label': 'ID',
    'order': 3,
    'show_in_table': true,
    'attributeRule': {
      'rules': [
        'number'
      ],
      'required': 0,
      'visible': 1,
      'locked': 1
    },
    'eavType': {
      'id': 7,
      'name': 'number'
    }
  },
  {
    'name': 'geometry',
    'label': 'Геометрия',
    'order': 3,
    'show_in_table': false,
    'attributeRule': {
      'rules': [
        'safe'
      ],
      'required': 1,
      'visible': 1,
      'locked': 0
    },
    'eavType': {
      'id': 10,
      'name': 'geometry'
    }
  }/*,
  {
    'name': 'note',
    'label': 'Примечание',
    'order': 700,
    'show_in_table': true,
    'attributeRule': {
      'rules': [
        'string',
        {
          'max': 255
        }
      ],
      'required': 0,
      'visible': 1,
      'locked': 1
    },
    'eavType': {
      'id': 1,
      'name': 'text'
    }
  },
  {
    'name': 'price',
    'label': 'Цена',
    'order': 4,
    'show_in_table': false,
    'attributeRule': {
      'rules': [
        'number'
      ],
      'required': 1,
      'visible': 1,
      'locked': 1
    },
    'eavType': {
      'id': 7,
      'name': 'number'
    }
  }*/
]
