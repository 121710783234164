import { styles } from '@/components/views/account/map/detail/common/mapStyles'

class Passport {
  constructor (props = null) {
    this.type = 'Feature'
    this.properties = {
      id: null,
      cadastr_number: null,
      object_name: null,
      territorial_affiliation: null,
      executing_organization: null,
      classification_code: null,
      classification_code_detail: null,
      land_function: null,
      owner: null,
      using_mode: null,
      passportEntities: [],
      length: null,
      width: null,
      area: null,
      address: null,
      type: null,
      origin_executor: null,
      origin_passportization_date: null,
      person_in_charge: null,
      project_id: null,
      style: styles.defaultStyle.passport,
      region_id: props && props.region_id ? props.region_id : null,
      passportImages: [],
      passportFiles: []
    }
    this.geometry = null
  }
}

export default Passport
